import team1 from "../../../Assets/img/IMG_7162.jpg";
import team2 from "../../../Assets/img/team-img-02.png";
import team3 from "../../../Assets/img/sher.png";
import team4 from "../../../Assets/img/team-img-04.png";
import team5 from "../../../Assets/img/Brian Meraz.jpeg";
import team6 from "../../../Assets/img/Brian Meraz.jpeg";


const teamData = [
  {
    id: 1,
    Img: team1,
    name: "Faruque Amin",
    title: "Founder & Blockchain Architect",
    details: "Driving Innovation and Strategy with Web3",
    twitterLink: "https://twitter.com/faruqueamin",
    linkedinLink: "https://www.linkedin.com/in/faruqueamin/",
  },
  {
    id: 2,
    Img: team2,
    name: "Thanvir Laskar",
    title: "Founder",
    details: "A visionary leader driving business growth and innovation through web3 technology",
    twitterLink: "https://twitter.com/thanvirlaskar",
    linkedinLink: "https://www.linkedin.com/in/thanvir-laskar-091a3b61/",
  },
  {
    id: 3,
    Img: team3,
    name: "Shehryar Khan",
    title: "Full Stack Developer",
    details: "I'm dedicated to empowering users through a decentralized, transparent ecosystem. Championing financial freedom and sustainability for a better digital future.",
    twitterLink: "https://twitter.com/shrrkhan",
    linkedinLink: "https://www.linkedin.com/in/shrrkhan/",
  },
  {
    id: 4,
    Img: team4,
    name: "Burraque Khattak",
    title: "Full Stack Developer",
    details: "Innovative thinker and software engineer working to revolutionize finance with blockchain and crypto solutions.",
    twitterLink: "#",
    linkedinLink: "https://www.linkedin.com/in/burraquekhattak/",
  },
  {
    id: 5,
    Img: team5,
    name: "Faraaz Motiwala",
    title: "Smart Contract Developer",
    details: "A visionary leader driving business growth and innovation through web3 technology",
    twitterLink: "https://twitter.com/faraazcodes",
    linkedinLink: "#",
  },
  {
    id: 6,
    Img: team6,
    name: "Brian Meraz",
    title: "Discord Manager",
    details: "A visionary leader driving business growth and innovation through web3 technology",
    twitterLink: "https://twitter.com/JustHigeki",
    linkedinLink: "https://www.linkedin.com/in/brian-meraz-776707269/",
  },
  // ... other team members
];

export default teamData;