import img1 from '../../../Assets/img/slider-img03.png'
import img3 from '../../../Assets/img/slider-img02.png'

const ourData = [
    {
        id: 1,
        Img: img3
    },
    {
        id: 2,
        Img: img1
    },
    {
        id: 3,
        Img: img3
    },
    {
        id: 1,
        Img: img1
    },
    {
        id: 2,
        Img: img3
    },
    {
        id: 3,
        Img: img1
    },

];

export default ourData;