import React from "react";
import twitterIcon from "../../../Assets/social media icons/twitter-icon.png";
import linkedinIcon from "../../../Assets/social media icons/linkedin-icon.png";

const OurTeamCard = ({ item }) => {
  const { Img, name, title, details, twitterLink, linkedinLink } = item;
  return (
    <div className="teamCard">
      <img src={Img} alt={name} className="teamImage" />
      <div className="card-body">
        <h2 className="card-title">
          <span>{name.split(" ")[0]} </span>
          {name.split(" ").slice(1).join(" ")}
        </h2>
        <h5 className="card-subtitle">{title}</h5>
        <p className="teamAbout">{details}</p>
        <div className="social-media">
          <a
            href={twitterLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={twitterIcon} alt="Twitter" />
          </a>
          <a
            href={linkedinLink}
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default OurTeamCard;